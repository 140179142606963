'use client';
import clsx from 'clsx';
import BasicLink from 'core/BasicLink';
import Image from 'next/image';
import { usePathname } from 'next/navigation';
import { useMemo, useState } from 'react';
import HeaderMobileMenu from 'shared/Header/HeaderLayout/HeaderMobileMenu';
import HeaderMenu from 'shared/Header/HeaderMenu';
import OfferButton from 'shared/sections/OfferButton';

import cn from './style.module.sass';

export default function Header() {
    const [openMenu, setOpenMenu] = useState(false);

    const pathName = usePathname();
    const isEnterprisePage = pathName.includes('/bitrix-enterprise');

    const headerBurgerGlobal = 'header-burger__global';

    const pathMainPage = pathName === '/';

    const handleToggle = () => {
        setOpenMenu(!openMenu);
    };

    const handleCloseMenu = () => {
        setOpenMenu(false);
    };

    const logoSrc = useMemo(() => {
        if (pathName.includes('/service/develop')) {
            return '/images/svg/header/logo_develop.svg';
        } else if (pathName.includes('/service/marketing')) {
            return '/images/svg/header/logo_marketing.svg';
        } else if (pathName.includes('/service/outstaff')) {
            return '/images/svg/header/logo_auts.svg';
        }
        return '';
    }, [pathName]);

    return (
        <header className={cn.header} id={cn.header}>
            <HeaderMobileMenu
                openMenu={openMenu}
                handleCloseMenu={handleCloseMenu}
                handleToggle={handleToggle}
            />
            <div className={cn['header__wrapper']}>
                <div className={cn['header__grid']}>
                    <div className={clsx(cn['header__item'], cn['header__item--burger'])}>
                        <div
                            role="presentation"
                            className={clsx(cn['header-burger'], {
                                [cn.active as string]: openMenu,
                            })}
                            onClick={handleToggle}
                        >
                            <div className={cn['header-burger__wrap']}>
                                <span
                                    className={clsx(
                                        cn[headerBurgerGlobal],
                                        cn['header-burger__global--top']
                                    )}
                                />
                                <span
                                    className={clsx(
                                        cn[headerBurgerGlobal],
                                        cn['header-burger__global--middle']
                                    )}
                                />
                                <span
                                    className={clsx(
                                        cn[headerBurgerGlobal],
                                        cn['header-burger__global--bottom']
                                    )}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={clsx(cn['header__item'], cn['header__item--center'])}>
                        <div className={cn['header-center']}>
                            <div
                                className={clsx(
                                    cn['header-center__item'],
                                    cn['header-center__item--left']
                                )}
                            >
                                <BasicLink href="/" className={cn['header-logo']}>
                                    <Image
                                        priority
                                        width={0}
                                        height={0}
                                        sizes="100vw"
                                        style={{ width: '100%', height: 'auto' }} // optional
                                        src="/images/svg/header/logo.svg"
                                        alt="Вебпрактик"
                                        loading={'eager'}
                                        className={clsx(
                                            cn['header-logo__img'],
                                            cn['header-logo__img--black']
                                        )}
                                    />
                                    {logoSrc !== '' && (
                                        <Image
                                            priority
                                            width={0}
                                            height={0}
                                            sizes="100vw"
                                            src={logoSrc}
                                            alt="Вебпрактик"
                                            loading={'eager'}
                                            className={clsx(
                                                cn['header-logo__img'],
                                                cn['header-logo__img--bottom'],
                                                cn[
                                                    pathName.includes('/service/marketing')
                                                        ? 'header-logo__img--marketing'
                                                        : ' '
                                                ]
                                            )}
                                        />
                                    )}
                                </BasicLink>
                            </div>
                            <div
                                className={clsx(
                                    cn['header-center__item'],
                                    cn['header-center__item--right']
                                )}
                            >
                                <div className={cn['header-center__menu']}>
                                    <HeaderMenu />
                                </div>
                                {!isEnterprisePage && (
                                    <div className={cn['header-center__btn']}>
                                        <OfferButton
                                            linkText={
                                                pathMainPage ? 'Стать клиентом' : 'Оставить заявку'
                                            }
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className={clsx(cn['header__item'], cn['header__item--lang'])}>
                        <BasicLink
                            className={cn['header-lang']}
                            href="https://webpractik.com"
                            target="_blank"
                        >
                            EN
                        </BasicLink>
                    </div>
                </div>
            </div>
        </header>
    );
}
