import(/* webpackMode: "eager" */ "/home/webpractik/web/prod.webpractik.ru.w6p.ru/releases/133/components/shared/Cookies/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/webpractik/web/prod.webpractik.ru.w6p.ru/releases/133/components/shared/Footer/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/webpractik/web/prod.webpractik.ru.w6p.ru/releases/133/components/shared/Header/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/webpractik/web/prod.webpractik.ru.w6p.ru/releases/133/components/shared/utilities/YandexMetrika/index.tsx");
;
import(/* webpackMode: "eager" */ "/home/webpractik/web/prod.webpractik.ru.w6p.ru/releases/133/node_modules/modern-normalize/modern-normalize.css");
;
import(/* webpackMode: "eager" */ "/home/webpractik/web/prod.webpractik.ru.w6p.ru/releases/133/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/home/webpractik/web/prod.webpractik.ru.w6p.ru/releases/133/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/home/webpractik/web/prod.webpractik.ru.w6p.ru/releases/133/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/home/webpractik/web/prod.webpractik.ru.w6p.ru/releases/133/styles/index.sass");
;
import(/* webpackMode: "eager" */ "/home/webpractik/web/prod.webpractik.ru.w6p.ru/releases/133/node_modules/next/font/local/target.css?{\"path\":\"lib/fonts/ProximaNova.ts\",\"import\":\"\",\"arguments\":[{\"fallback\":[\"system-ui\",\"Segoe UI\",\"Roboto\",\"Helvetica\",\"Arial\",\"sans-serif\",\"Apple Color Emoji\",\"Segoe UI Emoji\",\"Segoe UI Symbol\"],\"display\":\"swap\",\"src\":[{\"path\":\"../../public/fonts/ProximaNova/ProximaNova-Light.woff2\",\"weight\":\"300\"},{\"path\":\"../../public/fonts/ProximaNova/ProximaNova-Regular.woff2\",\"weight\":\"400\"},{\"path\":\"../../public/fonts/ProximaNova/ProximaNova-Semibold.woff2\",\"weight\":\"600\"},{\"path\":\"../../public/fonts/ProximaNova/ProximaNova-Bold.woff2\",\"weight\":\"700\"},{\"path\":\"../../public/fonts/ProximaNova/ProximaNova-Extrabld.woff2\",\"weight\":\"800\"}],\"variable\":\"--font-proximaNova\"}],\"variableName\":\"proximaNova\"}");
