'use client';
import clsx from 'clsx';
import BasicImage from 'core/BasicImage';
import { getExternalLink } from 'core/BasicImage/basic-image.util';
import BasicLink from 'core/BasicLink';
import { usePathname } from 'next/navigation';
import FooterSocial from 'shared/Footer/FooterSocial';

import cn from './style.module.sass';

export default function Footer() {
    const pathName = usePathname();
    const isEnterprisePage = pathName.includes('/bitrix-enterprise');
    const isCompanyPage = pathName.includes('/company');

    const pathMainPage = pathName === '/';
    const isMainPage = pathMainPage ? cn['footer--main'] : '';

    const footerInfoItem = 'footer-info__item';
    const footerRightItem = 'footer-right__item';

    return (
        <footer className={clsx(cn.footer, isMainPage)}>
            <div className={cn['footer-full__wrapper']}>
                <div className={cn['footer__wrapper']}>
                    <div className={cn['footer__grid']}>
                        <div className={clsx(cn['footer__item'], cn['footer__item--left'])}>
                            <div className={cn['footer-info']}>
                                <div
                                    className={clsx(
                                        cn[footerInfoItem],
                                        cn['footer-info__item--coop']
                                    )}
                                >
                                    <div className={cn['footer-info__coop']}>
                                        © {new Date().getFullYear()}
                                    </div>
                                </div>
                                <div className={cn[footerInfoItem]}>
                                    <div className={cn['footer-info__link-wrap']}>
                                        <BasicLink
                                            href="tel:84955405179"
                                            className={cn['footer-info__phone-link']}
                                        >
                                            +7 495 540-51-79
                                        </BasicLink>
                                    </div>
                                    <div
                                        className={clsx(
                                            cn['footer-info__link-wrap'],
                                            cn['footer-info__link-wrap--email']
                                        )}
                                    >
                                        <BasicLink
                                            href="mailto:info@webpractik.ru"
                                            className={cn['footer-info__email-link']}
                                        >
                                            info@webpractik.ru
                                        </BasicLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={clsx(cn['footer__item'], cn['footer__item--right'])}>
                            <div className={cn['footer-right']}>
                                {!isEnterprisePage && (
                                    <div
                                        className={clsx(
                                            cn[footerRightItem],
                                            cn['footer-right__item--career']
                                        )}
                                    >
                                        <BasicLink
                                            href={getExternalLink('/files/WebpractikPolicy.pdf')}
                                            target="_blank"
                                            className={cn['footer-career']}
                                            rel="noopener noreferrer"
                                        >
                                            Политика в отношении <br /> обработки персональных
                                            данных
                                        </BasicLink>
                                    </div>
                                )}
                                <div
                                    className={clsx(
                                        cn[footerRightItem],
                                        cn['footer-right__item--presentation']
                                    )}
                                >
                                    <BasicLink
                                        target="_blank"
                                        className={cn['footer-presentation']}
                                        rel="noopener noreferrer"
                                        href={getExternalLink(
                                            '/files/PresentationWebpractikNew.pdf'
                                        )}
                                    >
                                        <BasicImage
                                            width={22}
                                            height={22}
                                            src="/images/svg/presentation.svg"
                                            alt="Презентация агентства"
                                        />
                                        <span>Презентация агентства</span>
                                    </BasicLink>
                                </div>
                                <div
                                    className={clsx(
                                        cn[footerRightItem],
                                        cn['footer-right__item--social']
                                    )}
                                >
                                    <FooterSocial />
                                </div>
                                <div
                                    className={clsx(
                                        cn[footerRightItem],
                                        cn['footer-right__item--coop']
                                    )}
                                >
                                    <div className={cn['footer-info__coop']}>
                                        © {new Date().getFullYear()}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {isCompanyPage && (
                        <div className={cn['footer__grid']}>
                            <div className={clsx(cn['footer__item'], cn['footer__item--left'])}>
                                <div className={cn['footer-info']}>
                                    <div className={cn['footer-info-company']}>
                                        <div className={cn['footer-info-company-item-right']}>
                                            <BasicLink
                                                className={cn['footer-info__result']}
                                                href={getExternalLink('/files/СОУТ-Вебпрактик.pdf')}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                Результаты СОУТ 2024
                                            </BasicLink>
                                            <BasicLink
                                                className={cn['footer-info__result']}
                                                href={getExternalLink('/files/SOUT-2025.pdf')}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                Результаты СОУТ 2025
                                            </BasicLink>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </footer>
    );
}
