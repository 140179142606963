export const linkTop = [
    {
        // eslint-disable-next-line sonarjs/no-duplicate-string
        link: '/service/development',
        linkName: 'Digital-интегратор',
    },
    {
        // eslint-disable-next-line sonarjs/no-duplicate-string
        link: '/service/marketing',
        linkName: 'Интернет-маркетинг',
    },
    {
        // eslint-disable-next-line sonarjs/no-duplicate-string
        link: '/service/outstaff',
        linkName: 'Аутстаф',
    },
];

export const linkBot = [
    {
        link: '/company',
        linkName: 'О компании',
        isBlank: false,
    },
    {
        link: '/service/development',
        linkName: 'Разработка',
        isBlank: false,
    },
    {
        link: '/service/marketing',
        linkName: 'Маркетинг',
        isBlank: false,
    },
    {
        link: '/service/outstaff',
        linkName: 'Аутстаф',
        isBlank: false,
    },
    {
        link: '/cases',
        linkName: 'Кейсы',
        isBlank: false,
    },
    {
        link: '/contacts',
        linkName: 'Контакты',
        isBlank: false,
    },
    {
        link: 'https://team.webpractik.ru/',
        linkName: 'Команда',
        isBlank: true,
    },
];

export const link = [
    {
        link: '',
        linkName: 'О компании',
        isStatic: true,
        linkDownList: [
            {
                link: '/company',
                linkName: 'Об агентстве',
                isBlank: false,
            },
            {
                link: 'https://team.webpractik.ru/',
                isBlank: true,
                linkName: 'Команда',
            },
        ],
    },
    {
        link: '/service/development',
        linkName: 'Разработка',
        isBlank: false,
    },
    {
        link: '/service/marketing',
        linkName: 'Маркетинг',
        isBig: false,
    },
    {
        link: '/service/outstaff',
        linkName: 'Аутстаф',
    },
    {
        link: '/cases',
        linkName: 'Кейсы',
    },
    {
        link: '/contacts',
        linkName: 'Контакты',
    },
];
